<template>
  <b-card
  >
    <!-- form -->
    <b-form id="financePaymentForm" class="edit-form mt-2">
      <ValidationObserver ref="observer">
        <b-row>
          <b-col md="12">
            <div class="inner-card-title">基本信息</div>
          </b-col>
          <b-col v-for="(value,name,index) in basicInfo" :key="index + '1'" md="4">
            <xy-form :prop-data="value" v-model="prepaid[name]"></xy-form>
          </b-col>


          <b-col md="12">
            <div class="inner-card-title">收款账号</div>
          </b-col>
          <b-col v-for="(value,name,index) in collection" :key="index + '2'" md="4">
            <xy-form :prop-data="value" v-model="prepaid[name]"></xy-form>
          </b-col>
        </b-row>
        <b-row >
          <b-col md="12">
            <div class="inner-card-title">业务信息</div>
          </b-col>
          <b-col v-for="(value,name,index) in pay" :key="index + '3'" md="4">
            <xy-form :prop-data="value" v-model="prepaid[name]"></xy-form>
          </b-col>

        </b-row>
        <b-row>
          <b-col md="12">
            <div class="inner-card-title">其他信息</div>
          </b-col>
          <b-col md="12">
            <xy-textarea label-cols="1" id="remark" label="付款事由" :val.sync="prepaid.remark" rules="required"/>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload theme="files"
                                 :readonly="readonly"
                                 attachment_id="attachments"
                                 :id="prepaid.attachments"
                                 object_type="company_prepaid"
                                 :object_id="prepaid.prepaid_id"
                                 @change="onUploaded"
                                 v-if="prepaid.loaded"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>

      <b-row>
        <b-col
            cols="12"
            class="mt-50"
        >
          <xy-button
              variant="primary"
              class="mr-1"
              @click="save(0)"
          >
            保存
          </xy-button>
          <b-button
              class="mr-1"
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
<!--          <xy-button
              variant="primary"
              @click="save(1)"
          >
            保存并提交
          </xy-button>-->
        </b-col>
      </b-row>
    </b-form>
    <b-modal
        id="modal-single"
        centered
        :size="singleModalObj[singleKey].size || 'lg'"
        hide-footer
        :title="`请选择${singleModalObj[singleKey].modalName}`"
        ref="singleModal"
    >
      <company-select
          @table="onSelectSingle"
          ref="companySelect" :companyType="singleModalObj[singleKey].companyType"
          v-if="singleModalObj[singleKey].selectType === 'company'"
      >
      </company-select>

      <bank-account-list
          ref="ourBankSelects" :companyId="prepaid.supplier_id"
          @table="onSelectSingle"
          v-if="singleModalObj[singleKey].selectType === 'bank_account'"
      >
      </bank-account-list>
    </b-modal>
  </b-card>
</template>

<script>
import { onUnmounted, toRefs } from '@vue/composition-api'
import store from '@/store'
import { getCode, getCodeColor, getCodeLabel, getCodeOptions, toDate, toTime } from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import useEdit from './useEdit'
import XyForm from '@/views/components/xy/XyForm'
import XyTextarea from '@/views/components/xy/XyTextarea'
import XyButton from '@/views/components/xy/XyButton'
import companyprepaidStore from './companyprepaidStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import BankAccountList from '@/views/apps/bankaccount/bankaccount-modal/BankAccountList'

export default {
  components: {
    BankAccountList,
    AttachmentUpload,
    XyButton,
    XyTextarea,
    XyForm,
    CompanySelect
  },
  setup() {
    // Register module
    if (!store.hasModule('companyprepaid')) store.registerModule('companyprepaid', companyprepaidStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companyprepaid')) store.unregisterModule('companyprepaid')
    })

    const {
      methods: useMethods,
      state: useState,
      page: usePage,
    } = useEdit()

    return {
      ...toRefs(useState),
      ...usePage,
      ...useMethods,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss">
</style>
