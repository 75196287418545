import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import { onBeforeMount, provide, reactive, watch } from '@vue/composition-api'
import { getUserData } from '@/auth/utils'
import { second } from '@core/utils/dayjs/utils'
import salesoutbound from '@/router/routes/apps/salesoutbound'
import { getCodeLabel } from '@core/utils/filter'

export default function useEdit() {
  const toast = useToast()
  const {
    router,
    route
  } = useRouter()
  const user = getUserData()

  const setModel = (id, name, key) => {
    const k = key || state.singleKey
    state.prepaid[`${k}_id`] = id
    state.prepaid[`${k}_name`] = name
  }

  const init = async () => {
    state.id = route.value.query.id
    state.readonly = route.value.params.readonly
    provide('_disabled', state.readonly)

    if (state.id) {
      await methods.edit()
    } else {
      state.prepaid.creator = user.user_id
      state.prepaid.creator_name = user.full_name
      state.prepaid.loaded = true
    }
  }

  onBeforeMount(async () => {
    await init()
  })

  const methods = {
    async edit() {
      const res = await store.dispatch('companyprepaid/edit', { id: state.id })
      state.prepaid = Object.assign({}, state.prepaid, res.data.data)
      state.prepaid.creator_name = getCodeLabel("user",state.prepaid.creator)
    },

    cancel() {
      router.go(-1)
    },

    async save(status) {
      const success = await state.observer.validate()
      if (!success) {
        toast.error('请正确填写必填字段')
        return
      }
      const params = { ...state.prepaid }
      params.status = status
      store.dispatch('companyprepaid/save', params)
        .then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            router.push({ name: 'apps-companyprepaid-list' })
          } else {
            toast.error('保存失败')
          }
        })
    },
    showSingleModal(key) {
      state.singleKey = key
      if (key === 'supplier'){
        state.prepaid.bank_account_name = undefined
        state.prepaid.bank_account_type = undefined
        state.prepaid.bank_account_no = undefined
        state.prepaid.bank_name = undefined
        state.prepaid.bank_node_address = undefined
        state.prepaid.bank_swift_code = undefined
        state.prepaid.bank_is_outlands = undefined
      }
      state.singleModal.show()
    },
    onSelectSingle(item) {
      switch (state.singleModalObj[state.singleKey].selectType) {
        case 'company':
          setModel(item.company_id, item.company_name)
          break
        case 'bank_account':
          state.prepaid.bank_account_name = item.account_name
          state.prepaid.bank_account_no = item.account_no
          state.prepaid.bank_account_type = item.account_type + ""
          state.prepaid.bank_name = item.bank_name
          state.prepaid.bank_is_outlands = item.is_outlands + ""
          state.prepaid.bank_node_address = item.bank_node_address
          state.prepaid.bank_swift_code = item.bank_swift_code
          break
      }
      state.singleModal.hide()
    },
    onUploaded(id, attachment, result) {
      state.prepaid[id] = result
    },

  }

  const state = reactive({
    id: 0,
    readonly: false,
    prepaid: reactive({
      prepaid_no: undefined,
      supplier_id: undefined,
      supplier_name: undefined,
      create_time: undefined,
      creator: undefined,
      creator_name: undefined,

      bank_account_name: undefined,
      bank_account_type: '',
      bank_account_no: '',
      bank_name: undefined,
      bank_node_address: '',
      bank_swift_code: undefined,
      bank_is_outlands: '',

      company_id: undefined,
      company_name: undefined,
      prepay_amount: undefined,
      amount: undefined,
      prepay_type: undefined,
      total:undefined,
      use:undefined,
      last:undefined,

      status: 1,
      remark: undefined,

    }),

    singleModal: undefined,
    companySelect: undefined,
    singleKey: 'supplier',

    //
    itemList: undefined,

    singleModalObj: {
      supplier: {
        modalName: '收款单位',
        selectType: 'company',
        companyType: 'Supplier'
      },
      company: {
        modalName: '付款主体',
        selectType: 'company',
        companyType: 'Group'
      },
      bank_account_name: {
        modalName: '银行账户',
        selectType: 'bank_account',
      },
    },

    observer: undefined,

  })

  const page = {
    basicInfo: {
      prepaid_no: {
        type: 'input',
        attrs: {
          id: 'prepaid_no',
          label: '申请编号',
          readonly: true,
          placeholder: '保存后自动填充',
        },
      },
      supplier_name: {
        type: 'input',
        attrs: {
          id: 'supplier_name',
          label: '收款单位',
          rules: 'required',
          readonly: true,
          placeholder: '点击搜索收款单位',
        },
        on: {
          click: () => methods.showSingleModal('supplier')
        }
      },
     /* create_time: {
        type: 'datePicker',
        attrs: {
          id: 'create_time',
          label: '申请日期',
          rules: 'required',
          disabled: true,
        },
      },*/
      creator_name: {
        type: 'input',
        attrs: {
          id: 'creator_name',
          label: '申请人',
          readonly: true,
        },
      },
    },
    collection:{
      bank_account_name: {
        type: 'input',
        attrs: {
          id: 'bank_account_name',
          label: '收款人户名',
          rules: 'required',
          readonly: true,
          placeholder: '点击搜索收款人户名',
        },
        on: {
          click: () => methods.showSingleModal('bank_account_name')
        }
      },
      bank_account_type: {
        type: 'select',
        attrs: {
          id: 'bank_account_type',
          label: '账户类型',
          optionsType: 'bank_account_type',
          disabled:true
        },
      },
      bank_account_no: {
        type: 'input',
        attrs: {
          id: 'bank_account_no',
          label: '收款人账号',
          readonly: true,
        },
      },
      bank_name: {
        type: 'input',
        attrs: {
          id: 'bank_name',
          label: '收款行名称',
          readonly: true,
        },
      },
      bank_node_address: {
        type: 'input',
        attrs: {
          id: 'bank_node_address',
          label: '银行地址',
          readonly: true,
        },
      },
      bank_swift_code: {
        type: 'input',
        attrs: {
          id: 'bank_swift_code',
          label: '联行号',
          readonly: true,
        },
      },
      bank_is_outlands: {
        type: 'select',
        attrs: {
          id: 'bank_is_outlands',
          label: '是否境外收款方',
          optionsType: 'yesno',
          disabled: true
        },
      },
    },
    pay:{
      company_name: {
        type: 'input',
        attrs: {
          id: 'company_name',
          label: '付款主体',
          rules: 'required',
          readonly: true,
          placeholder: '点击搜索付款主体',
        },
        on: {
          click: () => methods.showSingleModal('company')
        }
      },
      prepay_amount: {
        type: 'input',
        attrs: {
          id: 'prepay_amount',
          label: '申请付款金额',
          rules: 'required',
        },
      },
      amount: {
        type: 'input',
        attrs: {
          id: 'amount',
          label: '实际付款金额',
          readonly: true,
        },
      },
      prepay_type: {
        type: 'select',
        attrs: {
          id: 'prepay_type',
          label: '付款类别',
          optionsType: 'prepay_type',
          rules:"required"
        },
      },
      total: {
        type: 'input',
        attrs: {
          id: 'total',
          label: '总额度',
          readonly: true,
        },
      },
      use: {
        type: 'input',
        attrs: {
          id: 'use',
          label: '已冲销金额',
          readonly: true,
        },
      },
      last: {
        type: 'input',
        attrs: {
          id: 'last',
          label: '剩余额度',
          readonly: true,
        },
      },
    }
  }

  watch(()=>state.prepaid.supplier_id,(newVal)=>{
    store.dispatch('companyprepaid/getInfoBySupplierId', { id: newVal }).then((res)=>{
      const item = res.data.data
      state.prepaid.total = item.prepaid_amount || 0
      state.prepaid.use = item.used_amount || 0
      state.prepaid.last = item.ext.last
    })
  })
  return {
      state,
      methods,
      page
  }
}
